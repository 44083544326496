import React, { FC } from 'react';

import { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form';

import { TUsers } from 'shared/services/types/users.types';

import { AccountForm } from './AccountForm';
import { UserForm } from './UserForm';

interface IProps {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  methodAccount: UseFormReturn<FieldValues, any, undefined>;
  methodsUser: UseFormReturn<FieldValues, any, undefined>;
  onSubmitUser: SubmitHandler<TUsers.CreateUser>;
  onSubmitAccount: SubmitHandler<TUsers.CreateAccount>;
  setBirthday: React.Dispatch<React.SetStateAction<string | undefined>>;
  birthday: string | undefined;
  methodsOptions: { label: string; value: string | number }[];
  onClose: () => void;
  userData?: TUsers.UserData;
  required?: string;
}
export const UserSteps: FC<IProps> = ({
  step,
  setStep,
  methodAccount,
  methodsUser,
  onSubmitAccount,
  onSubmitUser,
  birthday,
  methodsOptions,
  onClose,
  setBirthday,
  userData,
  required
}) => {
  switch (step) {
    case 2:
      return (
        <AccountForm
          methods={methodAccount}
          methodsOptions={methodsOptions}
          onSubmit={onSubmitAccount}
          setStep={setStep}
        />
      );

    default:
      return (
        <UserForm
          birthday={birthday}
          methods={methodsUser}
          methodsOptions={methodsOptions}
          onClose={onClose}
          onSubmit={onSubmitUser}
          setBirthday={setBirthday}
          userData={userData}
          setStep={setStep}
          required={required}
        />
      );
  }
};

export interface IStep {
  onSubmit: any;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  methods: UseFormReturn<FieldValues, any, undefined>;
  methodsOptions: { label: string; value: string | number }[];
}
