import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';

import { UsersService } from 'shared/services/users.service';

export const useGetMyProfile = () => {
  const token = localStorage.getItem('access');

  const result = useQuery({
    queryFn: () => UsersService.getMe(),
    queryKey: ['get me', token],
    retry: 0,
    enabled: !!token,
    select: ({ data }) => data
  });

  return result;
};
