import React, { PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import clsx from 'clsx';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';

interface IProps extends PropsWithChildren {
  onSubmit: any;
  methods: UseFormReturn<FieldValues, any, undefined>;
  className?: string;
  id?: string;
  padding?: string;
}

export const FormWrapper: React.FC<IProps> = ({
  className,
  methods,
  children,
  onSubmit,
  id,
  padding
}) => {
  return (
    <FormProvider {...methods}>
      <Box
        component='form'
        onSubmit={methods.handleSubmit(onSubmit)}
        sx={{ p: padding || '30px 24px' }}
        className={clsx(className, 'form')}
        id={id}>
        {children}
      </Box>
    </FormProvider>
  );
};
