import React, { FC, useEffect, useState } from 'react';

import { IStep } from '.';
import { Box, Button, Grid } from '@mui/material';

import { EMAIL_REGEX, ROLE_USER } from 'shared/constants';
import { DateRange } from 'shared/services/types/common.types';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { Input } from 'shared/ui/input';
import { Select } from 'shared/ui/select';

interface IProps extends IStep {
  userData: any;
  onClose: () => void;
  setBirthday: React.Dispatch<React.SetStateAction<string | undefined>>;
  birthday?: string;
  required?: string;
}

export const UserForm: FC<IProps> = ({
  methods,
  onSubmit,
  userData,
  onClose,
  birthday,
  methodsOptions,
  required
}) => {
  const [, setBday] = useState<DateRange>(null);
  const [isPassForgot, setIsPassForgot] = useState(!userData);

  const formErrors = methods.formState.errors;

  useEffect(() => {
    typeof birthday === 'string' && setBday(new Date(birthday));
  }, [birthday]);

  return (
    <FormWrapper onSubmit={onSubmit} methods={methods}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <Grid
          container
          direction={'column'}
          columnGap={'20px'}
          rowGap={15}
          wrap={'nowrap'}
          gridTemplateColumns={'1fr '}
          display='grid'
          gridTemplateRows='auto auto 1fr'>
          <Grid item>
            <Input
              methods={methods}
              placeholder='Text'
              label='Email *'
              name='email'
              rules={{
                required: required,
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'Enter the correct email'
                }
              }}
            />
          </Grid>
          <Grid item>
            <Input
              methods={methods}
              placeholder='Text'
              label='Name *'
              name='name'
              rules={{ required: required }}
            />
          </Grid>
          <Grid
            item
            sx={{
              order: userData ? '100' : '0',
              marginTop: isPassForgot ? '0' : '-14px',
              position: 'relative'
            }}>
            {isPassForgot ? (
              <Input
                methods={methods}
                placeholder='Text'
                label={userData ? 'New password *' : 'Password *'}
                name='password'
                isPass
                rules={{
                  required: required,
                  minLength: { message: 'Minimum length - 10', value: 10 }
                }}
              />
            ) : (
              <Button
                variant='text'
                onClick={() => setIsPassForgot(true)}
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'end'
                }}>
                Forgot password?
              </Button>
            )}
          </Grid>
          <Grid item>
            <Select
              name='method_id'
              placeholder='Text'
              label='Method_ID *'
              rules={{
                required: required
              }}
              options={methodsOptions}
            />
          </Grid>
          <Grid item>
            <Input
              methods={methods}
              placeholder='Text'
              label='Phone'
              name='phone'
              rules={{
                required: required,
                maxLength: { message: 'Maximum length - 11', value: 11 }
              }}
            />
          </Grid>

          <Grid item>
            <Select
              name='role'
              placeholder='Text'
              label='Role *'
              rules={{
                required: required
              }}
              options={ROLE_USER.map(item => ({ label: item.value, value: item.label }))}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        mt={'30px'}
        display={'flex'}
        gap={'16px'}
        alignItems={'center'}
        justifyContent={'center'}>
        <Button
          variant='contained'
          fullWidth
          onClick={onClose}
          sx={{
            maxWidth: 175,
            m: 0,
            borderRadius: 50,
            textTransform: 'none',
            background: '#E3E3E4',
            color: '#1D1B20',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#e2e2e2'
            },
            height: 40
          }}>
          Cancel
        </Button>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{
            m: 0,
            borderRadius: 50,
            textTransform: 'none',
            maxWidth: 175,
            height: 40,
            boxShadow: 'none'
          }}>
          Save
        </Button>
      </Box>
    </FormWrapper>
  );
};
