import { useQuery } from '@tanstack/react-query';

import { TUsers } from 'shared/services/types/users.types';
import { UsersService } from 'shared/services/users.service';

export const useGetAccounts = (data: TUsers.GetAccount) => {
  const {
    isLoading: isAccountsLoading,
    data: accounts,
    isError: isAaccountsError
  } = useQuery({
    queryFn: () => UsersService.getAccounts(data),
    queryKey: ['get account', data],
    retry: 0,
    select: ({ data }) => data
  });

  return { isAccountsLoading, accounts, isAaccountsError };
};
