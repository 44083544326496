import { FC } from 'react';

import { CircularProgress } from '@mui/material';

interface IProps {
  isSelect?: boolean;
  top?: string;
}
const CircularProgressCenter: FC<IProps> = ({ isSelect, top }) => {
  return (
    <CircularProgress
      style={{
        position: 'absolute',
        top: top ?? '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: isSelect ? 'gray' : undefined,
        width: isSelect ? 10 : undefined,
        height: isSelect ? 10 : undefined
      }}
    />
  );
};

export default CircularProgressCenter;
