import { FC, useEffect, useState } from 'react';

import { IStepProps } from '..';
import { Box, Button, Grid } from '@mui/material';
import { FieldValues, UseFormReturn } from 'react-hook-form';

import { CURRENCY } from 'shared/constants/common';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { Input } from 'shared/ui/input';
import { IModalProps, ModalTemplate } from 'shared/ui/modal-template';
import { Select } from 'shared/ui/select';
import { SelectSearch } from 'shared/ui/select-multi';
import { Tabs } from 'shared/ui/tabs';
import { hasFormValuesChanged } from 'shared/utils';

interface IProps extends IModalProps, IStepProps {
  onRegexEditor?: () => void;
  onSubmit: (data: any) => void;
  formOne: UseFormReturn<FieldValues, any, undefined>;
  formTwo: UseFormReturn<FieldValues, any, undefined>;
  activeTab: number;
}

const SOURCE_LIST = [
  { value: 'SMS/PUSH', label: 'SMS/PUSH' },
  { value: 'Email', label: 'Email' },
  { value: 'API', label: 'API' }
];

export const MainInfoModal: FC<IProps> = ({
  close,
  isOpen,
  onRegexEditor,
  onSubmit,
  formOne,
  formTwo,
  onNext,
  activeTab
}) => {
  const formErrors = formOne.formState.errors;
  const formErrorsTwo = formTwo.formState.errors;
  const isConfirmationNeeded = hasFormValuesChanged(formOne.watch(), []);

  const [activeId, setActiveId] = useState(activeTab || 0);

  useEffect(() => {
    if (activeTab !== undefined) {
      setActiveId(activeTab);
    }
  }, [activeTab]);

  const onClickActive = (id: number) => {
    setActiveId(id);
  };

  const onReset = () => {
    formOne.reset();
    formTwo.reset();
  };

  return (
    <>
      <ModalTemplate
        isConfirmationNeeded={isConfirmationNeeded}
        close={close}
        isOpen={isOpen}
        onBack={activeTab === 1 ? () => onClickActive(0) : undefined}
        titleText={'Add method'}
        hideBackdrop={false}
        reset={onReset}>
        <Box p={'0 15px'}>
          <Tabs
            activeTab={activeId}
            onClick={onClickActive}
            disabledHeader={activeTab !== 1}
            headerTabs={[
              { id: 0, title: 'Main' },
              { id: 1, title: 'Settings' }
            ]}>
            <FormWrapper
              onSubmit={() => {
                onNext?.();
                onClickActive(1);
              }}
              methods={formOne}
              padding=' 0'>
              <Box p={'30px 0'}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}>
                  <Grid container direction={'column'} rowGap={15} wrap={'nowrap'}>
                    <Grid item>
                      <Input
                        name='name'
                        methods={formOne}
                        label='Name *'
                        placeholder='Enter method name'
                        rules={{ required: 'Please, enter method name' }}
                      />
                    </Grid>
                    <Grid>
                      <Select
                        name='transaction_source'
                        placeholder='Transaction source'
                        label='Source *'
                        options={SOURCE_LIST}
                      />
                    </Grid>
                    <Grid item>
                      <Input
                        methods={formOne}
                        name='min_value'
                        label='Min value *'
                        type='number'
                        placeholder='Enter minimal sum'
                        rules={{ required: 'Please, enter minimal sum' }}
                      />
                    </Grid>
                    <Grid item sx={{ maxHeight: '350px' }}>
                      <SelectSearch
                        options={CURRENCY}
                        inputLabel='Currency *'
                        inputPlaceholder='Choose currency for payment method'
                        methods={formOne}
                        name='currency'
                      />
                    </Grid>
                    <Grid item>
                      <Input
                        methods={formOne}
                        name='decline_time'
                        label='Cancellation time (hours) *'
                        type='number'
                        placeholder='Enter decline time, h'
                        rules={{
                          required: 'Please, enter decline time, h',
                          validate: value => {
                            return /^[1-9]\d*$/.test(value) || 'Please enter a positive integer.';
                          }
                        }}
                      />
                    </Grid>

                    <Grid item>
                      <Input
                        methods={formOne}
                        name='ussd_command'
                        label='USSD command'
                        placeholder='Enter USSD command for payment method'
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display={'flex'} gap='10px'>
                  <Button
                    fullWidth
                    variant='outlined'
                    onClick={() => close()}
                    sx={{
                      borderRadius: 50,
                      textTransform: 'none',
                      height: 40,
                      marginTop: 15,
                      marginBottom: 3
                    }}>
                    Cancel
                  </Button>
                  <Button
                    fullWidth
                    type='submit'
                    variant='contained'
                    sx={{
                      borderRadius: 50,
                      textTransform: 'none',
                      height: 40,
                      marginTop: 15,
                      marginBottom: 3
                    }}>
                    Next
                  </Button>
                </Box>
              </Box>
            </FormWrapper>
            <FormWrapper onSubmit={onSubmit} methods={formTwo} padding=' 0'>
              <Box p={'30px 0'}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}>
                  <Grid container direction={'column'} rowGap={15} wrap={'nowrap'}>
                    <Grid item>
                      <Select
                        name='compare_field'
                        placeholder='Text'
                        label='Сomparisons'
                        options={[
                          { value: 'amount', label: 'Income' },
                          { value: 'remarks', label: 'Remarks' },
                          { value: 'amount_balance', label: 'Amount with balance' }
                        ]}
                      />
                    </Grid>
                    <Grid item>
                      <Input
                        methods={formTwo}
                        name='regex'
                        label='Regular expressions'
                        placeholder='Texts'
                        isEdit
                        onClickIcon={onRegexEditor}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display={'flex'} gap='10px'>
                  <Button
                    fullWidth
                    variant='outlined'
                    onClick={() => onClickActive(0)}
                    sx={{
                      borderRadius: 50,
                      textTransform: 'none',
                      height: 40,
                      marginTop: 15,
                      marginBottom: 3
                    }}>
                    Back
                  </Button>
                  <Button
                    fullWidth
                    type='submit'
                    variant='contained'
                    sx={{
                      borderRadius: 50,
                      textTransform: 'none',
                      height: 40,
                      marginTop: 15,
                      marginBottom: 3
                    }}>
                    Save
                  </Button>
                </Box>
              </Box>
            </FormWrapper>
          </Tabs>
        </Box>
      </ModalTemplate>
    </>
  );
};
