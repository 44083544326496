import { useEffect, useState } from 'react';

import { Add } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';

import { useGetMethods } from 'widgets/methods';

import { useGetUsers } from 'features/users/api/useGetUsers';
import { AddUserModal } from 'features/users/ui';

import { TUsers } from 'shared/services/types/users.types';
import { HeaderWrapper } from 'shared/ui/header-wrapper';

import { TableUsers } from './slices/TableUsers';

export const User = () => {
  const [idUsers, setIdUsers] = useState<number[]>([]);
  const [modalUserAdd, setModalUserAdd] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  const { methods } = useGetMethods();
  const [filters, setFilters] = useState({ page: 0, size: 20 });

  const { usersData, isUsersLoading, isUsersFetching } = useGetUsers({
    page: filters.page,
    size: filters.size
  });

  const onToggleUsers = (id: number) => {
    setIdUsers(prev => {
      const newIds = prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id];
      return newIds;
    });
  };

  const onActionUser = (type: 'edit' | 'delete', data: TUsers.UserData) => {
    if (type === 'edit') {
      setUserData(data);
      setModalUserAdd(true);
    } else {
      // TODO удаление пользователя/пользователей
    }
  };

  useEffect(() => {
    if (!modalUserAdd) {
      setUserData(null);
    }
  }, [modalUserAdd]);

  const onCloseModal = () => {
    setModalUserAdd(false);
    setUserData(null);
  };

  return (
    <>
      <HeaderWrapper>
        <Typography
          variant='h3'
          color='primary'
          sx={{ flexGrow: 1, fontSize: 28, lineHeight: '36px' }}>
          Users
        </Typography>

        <Button
          variant='contained'
          onClick={() => {
            setModalUserAdd(true);
          }}
          sx={{ padding: '10px 24px', paddingRight: '28px', maxWidth: 162 }}
          startIcon={<Add />}>
          Add user
        </Button>
      </HeaderWrapper>

      <TableUsers
        methods={methods}
        setFilter={setFilters}
        isUsersFetching={isUsersFetching}
        isUsersLoading={isUsersLoading}
        userIds={idUsers}
        usersData={usersData}
        onToggleUsers={onToggleUsers}
        onEditUser={data => {
          onActionUser('edit', data);
        }}
      />

      <AddUserModal
        isOpen={modalUserAdd}
        setIsOpen={setModalUserAdd}
        onClose={onCloseModal}
        userData={userData}
      />
    </>
  );
};
