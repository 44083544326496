import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import type { ClientRectObject } from '@popperjs/core';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  RegisterOptions,
  UseFormReturn
} from 'react-hook-form';

import { CircleArrowIcon, CloseIcon, KeyPasswordIcon } from 'shared/assets/icons';
import { generateValidPassword } from 'shared/utils';

import styles from './input.module.scss';

interface IProps {
  type?: string;
  name: string;
  label?: string;
  placeholder?: string;
  error?: string;
  helperText?: string;
  required?: boolean;
  methods: UseFormReturn<FieldValues, any, undefined>;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  isPass?: boolean;
  isEdit?: boolean;
  onClickIcon?: () => void;
}

export const Input: FC<IProps> = ({
  methods,
  name,
  rules,
  error,
  label,
  placeholder,
  required,
  type,
  helperText,
  isPass,
  isEdit,
  onClickIcon
}) => {
  const { control } = methods;
  const fieldError = error || methods?.formState.errors[name]?.message?.toString();
  const [showPassword, setShowPassword] = useState(false);
  const [toogler, setToogler] = useState(true);
  const password = useMemo(() => generateValidPassword(10), [toogler]);

  const resetField = () => {
    methods?.setValue(name, '');
  };
  return (
    <Box style={{ position: 'relative' }}>
      <Controller
        control={control}
        rules={rules}
        name={name}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            margin='normal'
            name={name}
            fullWidth
            required={required}
            inputProps={{
              className: styles.input
            }}
            id={name}
            label={label}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            placeholder={placeholder}
            InputLabelProps={{ shrink: true }}
            type={isPass ? (showPassword ? 'text' : 'password') : type}
            sx={{
              m: 0,
              p: 0,
              fieldset: {
                border: fieldError && '1px solid #b20000 '
              }
            }}
          />
        )}
      />
      {/* TODO Можно выделать в отдельный слайс */}
      <Box sx={{ position: 'absolute', right: 16, top: 16, display: 'flex', alignItems: 'center' }}>
        {isPass && (
          <>
            <Tooltip
              title={
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'start'}
                  sx={{ fontSize: '16px' }}>
                  <KeyPasswordIcon
                    style={{
                      // margin: '2px 6px 1px 7px',
                      fontSize: '24px',
                      width: '24px',
                      height: '24px',
                      color: '#878787'
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      maxWidth: '190px',
                      width: '190px',
                      margin: '0 6px',
                      cursor: 'pointer',
                      '& span': {
                        fontWeight: 600
                      },
                      '& p': {
                        fontFamily: 'Roboto',
                        fontSize: '15px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        letterSpacing: '0.25px',
                        color: '#878787'
                      }
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(password);
                      methods?.setValue(name, password);
                    }}>
                    <Typography variant='body1' component='span' key={toogler.toString()}>
                      {password}
                    </Typography>
                    <Typography variant='body1' color={'#878787'} component='p'>
                      Paste and save the generated password
                    </Typography>
                  </Box>
                  <IconButton
                    sx={{ padding: '4px', marginTop: '-4px' }}
                    onClick={() => {
                      setToogler(prev => !prev);
                    }}>
                    <CircleArrowIcon />
                  </IconButton>
                </Box>
              }
              arrow
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: ({ popper }: { popper: ClientRectObject }) => {
                          return [popper.width * -0.25, 0];
                        }
                      }
                    }
                  ],
                  sx: {
                    '.MuiTooltip-tooltip': {
                      padding: '8px 14px'
                    }
                  }
                }
              }}>
              <Box
                sx={{
                  maxHeight: '24px',
                  background: '#F9FFAF'
                }}>
                <KeyPasswordIcon style={{ margin: '2px 6px 1px 7px', color: '#A3A2A4' }} />
              </Box>
            </Tooltip>
            <IconButton
              sx={{ padding: 0, marginRight: '4px', marginLeft: '6px' }}
              aria-label={showPassword ? 'hide the password' : 'display the password'}
              onClick={() => {
                setShowPassword && setShowPassword(!showPassword);
              }}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </>
        )}
        {isEdit ? (
          <IconButton
            sx={{ cursor: onClickIcon ? 'pointer' : 'default', padding: 0 }}
            onClick={onClickIcon}>
            <EditIcon />
          </IconButton>
        ) : (
          !!methods?.watch(name) && (
            <IconButton sx={{ padding: '2px', width: '24px', height: '24px' }} onClick={resetField}>
              <CloseIcon height={'20px'} />
            </IconButton>
          )
        )}
      </Box>
      <Box
        style={{
          marginLeft: '16px',
          fontSize: helperText ? 13 : 12,
          color: helperText ? '#49454F' : '#b20000',
          textAlign: 'start',
          fontWeight: helperText ? 600 : 'normal'
        }}>
        {(helperText && <span>{helperText}</span>) || fieldError}
      </Box>
    </Box>
  );
};
