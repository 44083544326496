import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { MethodsService } from 'shared/services/methods.service';
import { TTransaction } from 'shared/services/types/transaction.types';
import { getTextFromBlob } from 'shared/utils';

export const useGetTransactionsExport = (data: TTransaction.getTransactionsExportData) => {
  const [errorMessage, setErrorMessage] = useState<any>(null);

  const {
    isLoading: isTransactionsExportLoading,
    data: transactions,
    isError: isTransactionsExportError,
    isFetching: isTransactionsExportFetching,
    refetch: refetchTransactions,
    isFetched: isTransactionsFetched,
    ...rest
  } = useQuery({
    queryFn: () => MethodsService.getTransactionsData(data),
    queryKey: ['get transactions export', data],
    enabled: false,
    retry: 0
  });

  const getMessageError = async () => {
    //@ts-ignore
    const response = rest.error?.response;
    if (response?.data instanceof Blob) {
      const data = await getTextFromBlob(response?.data);
      setErrorMessage(data);
    } else {
      setErrorMessage(rest.error);
    }
  };

  useEffect(() => {
    getMessageError();
  }, [rest.error]);

  return {
    isTransactionsExportLoading,
    transactions,
    isTransactionsExportError,
    isTransactionsExportFetching,
    refetchTransactions,
    isTransactionsFetched,
    ...rest,
    errorMessage
  };
};
