import { FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';

export const HeaderWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      gridColumn='2'
      gridRow='1'
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 10,
        py: 7,
        px: 8,
        borderRadius: 4,
        bgcolor: '#fff'
      }}>
      {children}
    </Box>
  );
};
