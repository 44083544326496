import { FC, useEffect, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { useGetMethods } from 'widgets/methods';

import { useCreateAccount } from 'features/add-account/api/useCreateAccount';

import { TUsers } from 'shared/services/types/users.types';
import { ModalTemplate } from 'shared/ui/modal-template';
import { hasFormValuesChanged } from 'shared/utils';
import { addMessageToast } from 'shared/utils/addMessageToast';

import { useActionUsers } from '../../api/useActionUsers';

import { UserSteps } from './slices';

interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  userData?: TUsers.UserData;
}

export const AddUserModal: FC<IProps> = ({ onClose, setIsOpen, isOpen, userData }) => {
  const [birthday, setBirthday] = useState<string | undefined>(userData?.birthday);
  const { methods: methodsData } = useGetMethods();
  const [step, setStep] = useState(1);
  const [userCreate, setUserCreate] = useState<TUsers.UserData | null>(null);

  const methods = useForm({ mode: 'onBlur' });

  const isConfirmationNeeded = hasFormValuesChanged(methods.watch(), userData);

  const methodsAccount = useForm({ mode: 'onBlur' });

  const { create, update } = useActionUsers();
  const { create: createAccount } = useCreateAccount();
  const { reset } = methods;

  useEffect(() => {
    if (isOpen && userData) {
      reset(userData);

      setBirthday(userData.birthday);
    } else if (!isOpen) {
      reset({ name: null });
      setUserCreate(null);
      setStep(1);
      methodsAccount.reset({ name: null });
    }
  }, [isOpen, userData]);

  const onSubmit: SubmitHandler<TUsers.CreateUser> = async data => {
    try {
      if (!userData) {
        const { data: user } = await create.mutateAsync({ ...data, birthday });
        addMessageToast('User created', 'success');
        setUserCreate(user);
        if (['Агент'].includes(user.role)) {
          setStep(2);
          methodsAccount.setValue('method_id', methods.getValues('method_id'));
        } else onClose();
      } else {
        await update.mutateAsync({ ...data, birthday, user_id: userData.id });
        addMessageToast('User updated', 'success');
        onClose();
      }
    } catch (error) {
      addMessageToast(error);
    }
  };

  const onSubmitAccount: SubmitHandler<TUsers.CreateAccount> = async data => {
    try {
      await createAccount.mutateAsync({ ...data, user_id: userCreate?.id });
      addMessageToast('Account created', 'success');
      onClose();
    } catch (error) {
      addMessageToast(error);
    }
  };
  const required = userData === null ? 'Required Field' : undefined;

  return (
    <ModalTemplate
      isConfirmationNeeded={isConfirmationNeeded}
      close={onClose}
      isOpen={isOpen}
      titleText={userData ? 'Edit user' : step === 2 ? 'Add account' : 'Add user'}
      hideBackdrop={false}
      onBack={() => setIsOpen(false)}
      reset={() => {
        reset();
        setBirthday(undefined);
        methods.clearErrors();
      }}
      maxWidth={step === 1 ? 413 : 715}>
      <UserSteps
        birthday={birthday}
        setBirthday={setBirthday}
        methodAccount={methodsAccount}
        methodsUser={methods}
        methodsOptions={methodsData?.map(item => ({ label: item.name, value: item.id })) || []}
        required={required}
        onClose={() => {
          reset();
          setBirthday(undefined);
          onClose();
          methods.clearErrors();
        }}
        onSubmitAccount={onSubmitAccount}
        onSubmitUser={onSubmit}
        setStep={setStep}
        step={step}
        userData={userData}
      />
    </ModalTemplate>
  );
};
