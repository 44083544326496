import { FC, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';

import { FormWrapper } from 'shared/ui/form-wrapper';
import { Select } from 'shared/ui/select';

interface IProps {
  text: string;
  setRegex: (data: { regex: string; names: string[] }) => void;
}

export const RegexEditor: FC<IProps> = ({ text, setRegex }) => {
  const methods = useForm();
  const [selectVisible, setSelectVisible] = useState(false);
  const [selections, setSelections] = useState<{ name: string; text: string; color: string }[]>([]); // Выделенные части текста
  const [currentSelection, setCurrentSelection] = useState<string | null>(null); // Текущее выделение для обработки

  const colors = ['#ffb3ba', '#baffc9', '#bae1ff', '#ffdfba', '#ffffba', '#baffb3']; // Цвета для выделений

  const deleteSection = (text: string) => {
    setSelections(prev => prev.filter(item => item.text !== text));
  };

  // Паттерны для различных типов данных
  const patterns = {
    text: '(.*?)' // Текстовые данные
  };

  // Функция проверки арабского текста
  const isArabicText = (text: string) => {
    const arabicPattern = /[\u0600-\u06FF]/; // Диапазон символов для арабского текста
    return arabicPattern.test(text);
  };

  // Обработка выделения текста
  const handleSelection = () => {
    const selection = window.getSelection()?.toString().trim();
    if (selection) {
      setCurrentSelection(selection); // Сохраняем текущее выделение
      setSelectVisible(true); // Показываем select
    }
  };

  useEffect(() => {
    const name = methods.watch('name');
    if (currentSelection && name) {
      setSelections(prevSelections => [
        ...prevSelections,
        {
          text: currentSelection,
          name: name,
          color: colors[prevSelections.length % colors.length]
        }
      ]);
      setCurrentSelection(null); // Сбрасываем текущее выделение
      setSelectVisible(false); // Скрываем select
      methods.setValue('name', null);
    }
  }, [methods.watch('name')]);

  // Генерация регулярного выражения с экранированием
  const generateRegex = () => {
    let modifiedText = text;

    selections.forEach(selection => {
      const pattern = patterns.text; // Используем только паттерн для текста

      // Экранирование выделенного текста, чтобы он корректно вписался в регулярное выражение
      const escapedSelection = selection.text.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

      // Заменяем выделенный текст на регулярный паттерн (убираем лишние скобки)
      modifiedText = modifiedText.replace(new RegExp(escapedSelection, 'g'), pattern);
    });

    // Добавляем экранирование символа '/', если он не экранирован
    modifiedText = modifiedText.replace(/(?<!\\)\//g, '\\/');

    // Добавляем экранирование для символа '+'
    modifiedText = modifiedText.replace(/\+/g, '\\+');

    // Устанавливаем флаг 'u' для поддержки Unicode
    modifiedText = modifiedText.replace(/\\u200e/g, '(?:\\u200e)?');

    setRegex({ regex: modifiedText, names: selections.map(item => item.name) });
  };

  useEffect(() => {
    generateRegex();
  }, [selections]);

  // Функция для рендера текста с выделением в HTML
  const renderHighlightedText = () => {
    let highlightedText = text;
    selections.forEach(selection => {
      const escapedSelection = selection.text.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
      highlightedText = highlightedText.replace(
        new RegExp(`(${escapedSelection})`, 'g'),
        `<span style="background-color: ${selection.color}; ">$1</span>`
      );
    });
    return highlightedText;
  };

  return (
    <Box width={'100%'} position='relative' overflow={'auto'} minWidth={'200px'}>
      {/* Слой с подсветкой текста */}
      <Box
        component='div'
        contentEditable
        suppressContentEditableWarning={true} // Убираем предупреждение о содержимом
        onMouseUp={handleSelection}
        style={{ fontSize: '18px' }}
        dangerouslySetInnerHTML={{ __html: renderHighlightedText() }}
        sx={{
          position: 'absolute',
          whiteSpace: 'pre-wrap',
          overflowWrap: 'break-word',
          fontFamily: 'Roboto',
          width: '100%',
          fontSize: '18px',
          lineHeight: '24px',
          letterSpacing: '1.1px',
          color: 'inherit',
          zIndex: 1,
          unicodeBidi: 'bidi-override',
          pointerEvents: 'none'
        }}
      />
      {/* Прозрачный textarea для взаимодействия */}
      <Box
        component='textarea'
        value={text}
        onMouseUp={handleSelection}
        placeholder='Вставьте текст сообщения сюда...'
        rows={10}
        sx={{
          width: '100%',
          unicodeBidi: 'bidi-override',
          border: 'none',
          resize: 'none',
          position: 'relative',
          fontFamily: 'Roboto',

          fontSize: '18px',
          lineHeight: '24px',
          p: 0,
          letterSpacing: '1.1px',
          zIndex: 2,
          background: 'transparent', // Прозрачный фон
          color: 'black', // Черный текст для взаимодействия
          ':focus': {
            outline: 'none'
          }
        }}
      />
      <FormWrapper methods={methods} onSubmit={() => {}} padding='0'>
        <Select
          name='name'
          disabled={!selectVisible}
          label='Enter a name for the selected text*'
          placeholder='Selected text'
          options={[
            { label: 'Any', value: 'any' },
            { label: 'Income', value: 'income' },
            { label: 'Balance', value: 'balance' },
            { label: 'Remarks', value: 'remarks' },
            { label: 'Withdrawal', value: 'withdrawal' }
          ]}
        />
      </FormWrapper>

      <Box display={'flex'} gap={'10px'} flexDirection={'column'} mt={'20px'}>
        {/* Отображение выделенных данных */}
        {selections.map((selection, index) => (
          <Box
            key={index}
            sx={{
              textAlign: 'left', // Выравнивание для арабского текста
              direction: 'ltr' // Направление выделенного текста
            }}
            p={'18px 0'}
            display={'flex'}
            gap={'10px'}
            borderBottom={'1px solid #E5E5E6'}
            justifyContent={'space-between'}>
            <Box textTransform={'capitalize'} borderRadius={'100px'} p={'6px '}>
              {selection.name}
            </Box>
            <Box
              borderRadius={'100px'}
              gap={'4px'}
              p={'6px 24px'}
              display={'flex'}
              alignItems={'center'}
              bgcolor={selection.color}
              sx={{
                svg: {
                  width: '100%',
                  height: '100%'
                }
              }}>
              {selection.text}
              <Box
                width={'16px'}
                onClick={() => deleteSection(selection.text)}
                sx={{ cursor: 'pointer' }}
                height={'16px'}>
                <CloseIcon fontSize={'small'} />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
