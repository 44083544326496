import { useQuery } from '@tanstack/react-query';

import { MethodsService } from 'shared/services/methods.service';

export const useGetMethods = () => {
  const {
    isLoading: isMethodsLoading,
    data: methods,
    isError: isMethodsError
  } = useQuery({
    queryFn: () => MethodsService.getMethods(),
    queryKey: ['get methods'],
    retry: 0,
    select: ({ data }) => data.items
  });

  return { isMethodsLoading, methods, isMethodsError };
};
