import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { TStatusType } from 'shared/services/types/common.types';

type FilterType = {
  date_from?: string | null;
  date_to?: string | null;
  account_id?: string;
  status?: TStatusType;
};

interface StoreState {
  isFilterOpen: boolean;
  setIsFilterOpen: (isFilterOpen: boolean) => void;
  filter: FilterType;
  setFilter: (newFilter: FilterType) => void;
}

const useFilterStore = create<StoreState>()(
  persist(
    set => ({
      isFilterOpen: false,
      setIsFilterOpen: newFilter => set({ isFilterOpen: newFilter }),
      filter: {},
      setFilter: newFilter => set({ filter: newFilter })
    }),
    {
      name: 'filter-storage'
    }
  )
);

export default useFilterStore;
