import { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import useCurrentUrl from './useCurrentUrl';

const useRefetchUsers = () => {
  const queryClient = useQueryClient();

  const currentMethod = useCurrentUrl();

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['get account']
    });
  }, [currentMethod]);
};

export default useRefetchUsers;
