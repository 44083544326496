import { useLayoutEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { User } from 'widgets/users';

import { useUser } from 'shared/hooks';
import { Layout } from 'shared/ui/layout';

const UsersPage = () => {
  const { rolesAccess } = useUser();
  const isAccess = rolesAccess(['Администратор', 'Менеджер']);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (!isAccess) {
      navigate('/');
    }
  }, [isAccess]);

  return (
    <Layout bankName='Users' isSingleMethod hiddenHeader>
      <User />
    </Layout>
  );
};

export default UsersPage;
