import Cookies from 'js-cookie';

import { TTokens } from '../types/common.types';

export const saveTokensStorage = (data: TTokens) => {
  localStorage.setItem('access', data.access_token || '');
  localStorage.setItem('refresh', data.refresh_token || '');
  window.dispatchEvent(new Event('storage'));
};

export const removeTokensStorage = () => {
  localStorage.removeItem('access');
  localStorage.removeItem('refresh');
  Cookies.remove('token');
  window.dispatchEvent(new Event('storage'));
};
