import { useMutation } from '@tanstack/react-query';

import { AuthService } from 'shared/services/auth/auth.service';
import { TAuth } from 'shared/services/types/auth.types';

export const useActionAuth = () => {
  const loginUser = useMutation({
    mutationFn: (data: TAuth.Login) => AuthService.login(data),
    mutationKey: ['login user']
  });

  return { loginUser };
};
