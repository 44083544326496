import { FC, useMemo } from 'react';

import { TableCell, TableRow, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';

import { useGetApplications } from 'features/applications';
import { useGetTransactions } from 'features/transactions';

import {
  ApplicationsTableIcon,
  CompareIcon,
  MailIcon,
  TransactionsTableIcon
} from 'shared/assets/icons';
import { useGetDate } from 'shared/hooks';
import { TApplications } from 'shared/services/types/application.types';
import { DateRange, TFilters, TStatusType } from 'shared/services/types/common.types';
import { TTransaction } from 'shared/services/types/transaction.types';
import CircularProgressCenter from 'shared/ui/CircularProgressCenter';
import { MethodTableLayout } from 'shared/ui/method-table-layout';
import { Status } from 'shared/ui/status';
import { TableContentWrapper } from 'shared/ui/table-content-wrapper';

import { COLUMNS_A } from '../mock-data/MOCK_COL_A';
import { COLUMNS_T } from '../mock-data/MOCK_COL_T';

interface IProps {
  matchTransaction: (id: number) => void;
  page: number;
  pageSize: number;
  status?: TStatusType;
  accountNumber: string;
  dateFilter: DateRange;
  methodID: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  onSubmitFilters: (data: TFilters) => void;
  openMessagesModal: (message: string) => void;
}

export const DefaultTables: FC<IProps> = ({
  matchTransaction,
  page,
  pageSize,
  status,
  accountNumber,
  dateFilter,
  methodID,
  setPageSize,
  setPage,
  onSubmitFilters,
  openMessagesModal
}) => {
  const { getDate } = useGetDate();
  const filterT = useMemo(() => {
    return {
      size: pageSize,
      page: page,
      order_by: 'id',
      method_id: methodID,
      ...(methodID === 18
        ? {
            subagent: accountNumber ? +accountNumber : null
          }
        : {
            account_id: accountNumber ? +accountNumber : null
          }),
      status: status ? status : undefined,
      is_desc_sort: true,
      date_from: getDate(0, dateFilter),
      date_to: getDate(1, dateFilter)
    } as TTransaction.GetTransaction;
  }, [onSubmitFilters, page, pageSize]);

  const filterA = useMemo(() => {
    return {
      size: pageSize,
      page: page,
      order_by: 'id',
      method_id: methodID,
      ...(methodID === 18
        ? {
            subagent: accountNumber ? +accountNumber : null
          }
        : {
            account_id: accountNumber ? +accountNumber : null
          }),
      status: status ? status : undefined,
      is_desc_sort: true,
      date_from: getDate(0, dateFilter),
      date_to: getDate(1, dateFilter)
    } as TApplications.GetApplication;
  }, [onSubmitFilters, page, pageSize]);

  const { isTransactionsLoading, transactions } = useGetTransactions(filterT);

  const { isApplicationsLoading, applications } = useGetApplications(filterA);

  const total = useMemo(() => {
    if (applications && transactions)
      return applications.total > transactions.total ? applications.total : transactions.total;
  }, [applications, transactions]);

  return (
    <MethodTableLayout
      pageSize={pageSize}
      setPageSize={setPageSize}
      page={page}
      setPage={setPage}
      total={total || 0}>
      <TableContentWrapper
        columns={COLUMNS_A}
        thicon={
          <Tooltip title='Applications' arrow disableInteractive>
            <ApplicationsTableIcon style={{ display: 'block', marginLeft: '16px' }} />
          </Tooltip>
        }>
        {isApplicationsLoading ? (
          <CircularProgressCenter top='120%' />
        ) : (
          <>
            {applications?.items.map((row, id) => {
              const accountId = methodID === 18 ? row.subagent_id : row.account_id;
              const status = row?.status as TStatusType;

              return (
                <TableRow key={id}>
                  <TableCell align='left'>
                    <Status status={status} />
                  </TableCell>
                  <TableCell align='left'>{row.id}</TableCell>
                  <TableCell align='left'>
                    {format(fromZonedTime(row?.timestamp, 'UTC'), 'dd/MM/yyyy HH:mm:SS')}
                  </TableCell>
                  <TableCell align='left'>{row.transaction_id}</TableCell>
                  <TableCell align='left'>{accountId}</TableCell>
                  <TableCell align='left'>{row.remarks}</TableCell>
                  <TableCell align='left'>{row.amount}</TableCell>
                  <TableCell align='right' sx={{ cursor: 'pointer', color: '#AEAAAB' }}>
                    <Tooltip title='Match' arrow disableInteractive>
                      <CompareIcon
                        onClick={() => {
                          matchTransaction(row.id);
                        }}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </>
        )}
      </TableContentWrapper>
      <TableContentWrapper
        columns={COLUMNS_T}
        thicon={
          <Tooltip title='Transactions' arrow disableInteractive>
            <TransactionsTableIcon style={{ display: 'block', marginLeft: '16px' }} />
          </Tooltip>
        }>
        {isTransactionsLoading ? (
          <CircularProgressCenter top='120%' />
        ) : (
          <>
            {transactions?.items.map((row, id) => {
              const accountId = methodID === 18 ? row.subagent_id : row.account_id;
              const status = row?.status as TStatusType;

              return (
                <TableRow key={id}>
                  <TableCell align='left'>
                    <Status status={status} />
                  </TableCell>
                  <TableCell align='left'>{row.id}</TableCell>
                  <TableCell align='left'>
                    {format(fromZonedTime(row?.timestamp, 'UTC'), 'dd/MM/yyyy HH:mm:SS')}
                  </TableCell>
                  <TableCell align='left'>{row.application_id}</TableCell>
                  <TableCell align='left'>{accountId}</TableCell>
                  <TableCell align='left'>{row.remarks}</TableCell>
                  <TableCell align='left'>{row.income}</TableCell>
                  <TableCell align='right' sx={{ cursor: 'pointer' }}>
                    <Tooltip
                      title='Messages'
                      arrow
                      disableInteractive
                      placement='top-start'
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: () => {
                                  return [10, 0];
                                }
                              }
                            }
                          ]
                        }
                      }}>
                      {
                        <>
                          {!!row.message && (
                            <MailIcon
                              onClick={() => {
                                openMessagesModal(row?.message || '');
                              }}
                            />
                          )}
                        </>
                      }
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </>
        )}
      </TableContentWrapper>
    </MethodTableLayout>
  );
};
