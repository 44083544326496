import { useRef } from 'react';

export default function useScrollPercentage() {
  const scrollbarRef = useRef(null);
  const customScrollRef = useRef<HTMLDivElement>(null);
  const customScrollThumbsRef = useRef<HTMLDivElement>(null);

  const handleScroll = (event: any) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const scrollPercentage = scrollTop / (scrollHeight - clientHeight);
    const customScrollbarThumb = customScrollThumbsRef?.current;
    const scrollbarHeight = customScrollRef?.current?.clientHeight;
    if (scrollbarHeight) {
      const thumbHeight = Math.max((clientHeight / scrollHeight) * scrollbarHeight, 30);
      const thumbTop = scrollPercentage * (scrollbarHeight - thumbHeight);

      if (customScrollbarThumb) {
        customScrollbarThumb.style.height = `${thumbHeight}px`;
        customScrollbarThumb.style.top = `${thumbTop}px`;
      }
    }
  };

  const handleThumbDrag = (event: any) => {
    const customScrollbarThumb = customScrollThumbsRef?.current;
    const customScrollbar = customScrollRef?.current;
    const content = scrollbarRef?.current as any;

    const startY = event.clientY;
    const startTop = customScrollbarThumb?.offsetTop;

    if (customScrollbarThumb && customScrollbar && content && startTop) {
      const onMouseMove = (moveEvent: any) => {
        const deltaY = moveEvent.clientY - startY;
        const newTop = Math.min(
          Math.max(startTop + deltaY, 0),
          customScrollbar.clientHeight - customScrollbarThumb.clientHeight
        );
        const scrollPercentage =
          newTop / (customScrollbar.clientHeight - customScrollbarThumb.clientHeight);
        content.scrollTop(
          scrollPercentage * (content.getScrollHeight() - content.getClientHeight())
        );
      };

      const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    }
  };

  return { handleScroll, handleThumbDrag, scrollbarRef, customScrollRef, customScrollThumbsRef };
}
