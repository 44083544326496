import { useMutation, useQueryClient } from '@tanstack/react-query';

import { MethodsService } from 'shared/services/methods.service';
import { TApplications } from 'shared/services/types/application.types';

export const useChangeApplication = () => {
  const queryClient = useQueryClient();

  const match = useMutation({
    mutationFn: (data: TApplications.ChangeApplication) => MethodsService.changeApplication(data),
    mutationKey: ['match applications'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get applications'] });
      queryClient.invalidateQueries({ queryKey: ['get transactions'] });
    }
  });

  return { match };
};
