//@ts-nocheck
import { ChangeEvent, FC } from 'react';

import styled from '@emotion/styled';
import { Switch, SwitchProps } from '@mui/material';

interface IProps {
  props?: SwitchProps;
  value: boolean;
  setValue: (event: ChangeEvent<HTMLInputElement>) => void;
}

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 24,
  padding: 0,
  margin: 1,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#185AC2',
        opacity: 1,
        border: 0,
        ...theme.applyStyles('dark', {
          backgroundColor: '#4B96E7'
        })
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#3479E6',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[600]
      })
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
      ...theme.applyStyles('dark', {
        opacity: 0.3
      })
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20
  },
  '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    backgroundColor: '#777779',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    }),
    ...theme.applyStyles('dark', {
      backgroundColor: '#7A7F89'
    })
  },

  '&:hover': {
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          backgroundColor: '#3479E6',
          ...theme.applyStyles('dark', {
            backgroundColor: '#87B9EF'
          })
        }
      },
      '& + .MuiSwitch-track': {
        backgroundColor: '#949497',
        ...theme.applyStyles('dark', {
          backgroundColor: '#A6AAB0'
        })
      }
    }
  },
  '&:active': {
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          backgroundColor: '#1C69E1',
          ...theme.applyStyles('dark', {
            backgroundColor: '#69A7EB'
          })
        }
      },
      '& + .MuiSwitch-track': {
        backgroundColor: '#59595B',
        ...theme.applyStyles('dark', {
          backgroundColor: '#666A72'
        })
      }
    }
  }
}));

export const StyledSwitch: FC<IProps> = ({ value, setValue }) => {
  return <IOSSwitch defaultChecked={value} value={value} onChange={setValue} />;
};
