import { Children, FC, PropsWithChildren, useEffect, useState } from 'react';

import { Box } from '@mui/material';

interface IProps extends PropsWithChildren {
  headerTabs: { id: number; title: string }[];
  activeTab?: number;
  onClick?: (id: number) => void;
  disabledHeader?: boolean;
}
export const Tabs: FC<IProps> = ({ headerTabs, children, activeTab, onClick, disabledHeader }) => {
  const [activeId, setActiveId] = useState<null | number>(activeTab || 0);

  const onClickActiveId = (id: number) => {
    if (!disabledHeader) {
      setActiveId(id);
      onClick?.(id);
    }
  };

  useEffect(() => {
    if (activeTab !== undefined) {
      onClickActiveId(activeTab);
    }
  }, [activeTab]);
  return (
    <div>
      <Box display={'flex'}>
        {headerTabs.map(item => (
          <Box
            key={item.id}
            flex={'1 1 50%'}
            sx={{ cursor: disabledHeader ? 'default' : 'pointer' }}
            p={'14px '}
            textAlign={'center'}
            borderBottom={item.id === activeId ? '2px solid #185AC2' : undefined}
            onClick={() => onClickActiveId(item.id)}>
            {item.title}
          </Box>
        ))}
      </Box>
      <Box>
        {Children.map(children, (child, index) => {
          if (activeId === index) return <>{child}</>;
          return null;
        })}
      </Box>
    </div>
  );
};
