import { FC } from 'react';

import { IStep } from '.';
import { Box, Button, Grid } from '@mui/material';

import { CURRENCY } from 'shared/constants/common';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { Input } from 'shared/ui/input';
import { Select } from 'shared/ui/select';

interface IProps extends IStep {}

export const AccountForm: FC<IProps> = ({ methods, onSubmit, setStep, methodsOptions }) => {
  const formErrors = methods.formState.errors;
  return (
    <FormWrapper onSubmit={onSubmit} methods={methods}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <Grid
          container
          direction={'column'}
          columnGap={'20px'}
          rowGap={'34px'}
          wrap={'nowrap'}
          gridTemplateColumns={'1fr 1fr'}
          display='grid'
          gridTemplateRows='auto auto 1fr'>
          <Grid item>
            <Input
              name='user_name'
              placeholder='Text'
              required
              label='Телефон или почта пользователя'
              rules={{ required: 'Required field' }}
              methods={methods}
            />
          </Grid>

          <Grid item>
            <Input
              name='account_name'
              required
              placeholder='Text'
              label='Имя пользователя'
              rules={{ required: 'Required field' }}
              methods={methods}
            />
          </Grid>

          <Grid item>
            <Select
              required
              name='method_id'
              placeholder='Text'
              disabled
              label='Метод'
              rules={{
                required: 'Required field'
              }}
              options={methodsOptions}
            />
          </Grid>
          <Grid item>
            <Input
              name='account_id'
              required
              placeholder='Text'
              label='Id аккаунта'
              rules={{
                required: 'Required field'
              }}
              methods={methods}
            />
          </Grid>
          <Grid item>
            <Input
              name='password'
              placeholder='Text'
              label='Токен для приложения'
              rules={{ maxLength: { message: 'Maximum length - 100', value: 100 } }}
              methods={methods}
            />
          </Grid>
          <Grid item>
            <Input
              name='bank_login'
              placeholder='Text'
              label='Логин от личного кабинета'
              methods={methods}
            />
          </Grid>
          <Grid item>
            <Input
              name='bank_password'
              placeholder='Text'
              label='Пароль от личного кабинета'
              rules={{ maxLength: { message: 'Maximum length - 100', value: 100 } }}
              methods={methods}
            />
          </Grid>
          <Grid item sx={{ maxHeight: '350px' }}>
            <Select
              name='currency'
              label='Currency *'
              options={CURRENCY}
              placeholder='Choose currency for payment method'
              rules={{
                required: 'Please, choose currency'
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        mt={'30px'}
        display={'flex'}
        gap={'24px'}
        alignItems={'center'}
        justifyContent={'center'}>
        <Button
          variant='contained'
          fullWidth
          onClick={() => setStep(1)}
          sx={{
            maxWidth: 171,
            m: 0,
            borderRadius: 50,
            textTransform: 'none',
            background: '#E3E3E4',
            color: '#1D1B20',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#e2e2e2'
            }
          }}>
          Back
        </Button>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{ m: 0, borderRadius: 50, textTransform: 'none', maxWidth: 171, boxShadow: 'none' }}>
          Save
        </Button>
      </Box>
    </FormWrapper>
  );
};
