import { FC } from 'react';

import { Box, MenuItem } from '@mui/material';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  RegisterOptions,
  useFormContext
} from 'react-hook-form';

import { FilterItem } from '../filter-item';

interface IProps {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  options: { label: string | number; value: string | number }[];
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  helperText?: string;
  isFooter?: boolean;
}
export const Select: FC<IProps> = ({
  name,
  rules,
  label,
  placeholder,
  options,
  required,
  disabled,
  isLoading,
  helperText
}) => {
  const { control, formState } = useFormContext();
  const fieldError = formState.errors[name]?.message?.toString();

  return (
    <Box style={{ position: 'relative' }}>
      <Controller
        control={control}
        rules={rules}
        name={name}
        render={({ field: { onChange, onBlur, value } }) => (
          <FilterItem
            control={control}
            rules={rules}
            name={name}
            onBlur={onBlur}
            labelInner={label + (required ? ' *' : '')}
            placeholder={placeholder}
            value={value}
            setValue={onChange}
            htmlId={name}
            disabled={disabled}
            isLoading={isLoading}>
            {options.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </FilterItem>
        )}
      />
      <Box
        style={{
          marginLeft: '16px',
          fontSize: helperText ? 13 : 12,
          color: helperText ? '#49454F' : '#b20000',
          textAlign: 'start',
          fontWeight: helperText ? 600 : 'normal'
        }}>
        {(helperText && <span>{helperText}</span>) || fieldError}
      </Box>
    </Box>
  );
};
