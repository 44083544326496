import { FC, useEffect, useState } from 'react';

import { Box, TextField } from '@mui/material';
import { format } from 'date-fns';

import { DateRange } from 'shared/services/types/common.types';

interface IProps {
  value: DateRange;
  mono?: boolean;
  calendarRect?: DOMRect;
}

export const CustomHeader: FC<IProps> = ({ value, mono, calendarRect }) => {
  const [toogler, setToogler] = useState(true);
  useEffect(() => {
    setToogler(prev => !prev);
  }, [calendarRect]);
  return (
    <Box
      zIndex='3'
      className='custom-header'
      key={toogler.toString()}
      sx={{
        position: 'fixed',
        top: calendarRect?.top,
        left: calendarRect?.left,
        width: calendarRect?.width || '150px',
        height: '40px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'stretch',
        flexWrap: 'nowrap',
        pointerEvents: 'none',
        padding: '0',
        '& p': {
          color: '#44464F',
          fontFamily: 'Roboto',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: ' 400',
          lineHeight: '24px',
          letterSpacing: '0.15px',
          padding: '0px 10px',
          m: '8px 0',
          borderLeft: '1px solid #BDC1D2',
          borderRight: '1px solid #BDC1D2'
        },
        '.MuiFormControl-root': {
          width: '100%',
          p: '8px 10px'
        },
        '.MuiInputBase-root': {
          margin: '0 auto',
          input: {
            color: '#185AC2',
            '&:placeholder-shown': {
              color: '#90939B'
            }
          },
          '&:before': {
            display: 'none'
          },
          '& input': {
            textAlign: 'center',
            p: '0'
          }
        }
      }}>
      {mono && (
        <>
          <TextField
            id='standard-basic'
            label={null}
            variant='standard'
            placeholder='mm/dd/yyyy'
            value={
              //@ts-ignore
              value !== null ? format(value, 'MMM dd, yyyy') : ''
            }
          />
        </>
      )}
      {!mono && (
        <>
          <TextField
            id='standard-basic'
            label={null}
            variant='standard'
            placeholder='mm/dd/yyyy'
            value={
              //@ts-ignore
              value && value !== null && value[0] !== null ? format(value[0], 'MMM dd, yyyy') : ''
            }
          />
          <p>to</p>
          <TextField
            id='standard-basic'
            label={null}
            variant='standard'
            placeholder='mm/dd/yyyy'
            value={
              //@ts-ignore
              value && value !== null && value[1] !== null ? format(value[1], 'MMM dd, yyyy') : ''
            }
          />
        </>
      )}
    </Box>
  );
};
