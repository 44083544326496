import { Container } from '@mui/material';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import styles from './app.module.sass';
import './index.css';
import { TanstackQueryClientProvider } from './provider/react-query';
import { router } from './routes';

export const App = () => {
  return (
    <TanstackQueryClientProvider>
      <Container
        component='main'
        sx={{
          '::-webkit-scrollbar': {
            width: '100px'
          }
        }}
        className={styles.container}
        maxWidth='xs'>
        <ToastContainer />
        <RouterProvider router={router} />
      </Container>
    </TanstackQueryClientProvider>
  );
};
