import { create } from 'zustand';

import { TAuth } from 'shared/services/types/auth.types';

const defaultStore = {
  otp_verified: false,
  user: {}
} as TAuth.LoginSuccess;

interface StoreState extends TAuth.LoginSuccess {
  toogleOtp: (isSelected: boolean) => void;
  updateUser: (data: TAuth.LoginSuccess) => void;
  logout: () => void;
}

const useAuthStore = create<StoreState>()(set => ({
  ...defaultStore,
  toogleOtp: (isOtpVerified: boolean) => set(() => ({ otp_verified: isOtpVerified })),
  updateUser: (data: TAuth.LoginSuccess) => set(() => ({ user: data.user })),
  logout: () => set(state => ({ ...state, ...defaultStore }))
}));

export default useAuthStore;
