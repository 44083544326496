import { toFormData } from 'axios';

import { axiosInstance } from 'shared/api';
import { getUserUrl } from 'shared/api/config/api.config';

import { TAuth } from '../types/auth.types';

export const TwoFactorAuthService = {
  async checkCode(data: TAuth.TwoFactorCheck) {
    const response = await axiosInstance.post<TAuth.TwoFactorCheck>(
      getUserUrl('/check-otp-code'),
      toFormData(data)
    );
    return response;
  },
  async getQR() {
    const response = await axiosInstance.get<TAuth.TwoFactorCreate>(getUserUrl('/get-otp-qr'));
    return response.data;
  }
};
