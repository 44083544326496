import { FC } from 'react';

import { Add } from '@mui/icons-material';
import { Box, Button, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';

import { TUsers } from 'shared/services/types/users.types';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { Input } from 'shared/ui/input';
import { ModalTemplate } from 'shared/ui/modal-template';
import { hasFormValuesChanged } from 'shared/utils';
import { addMessageToast } from 'shared/utils/addMessageToast';

import { useCreateAccount } from '../api/useCreateAccount';

interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  methodID: number;
}

export const AddAccountModal: FC<IProps> = ({ isOpen, setIsOpen, methodID }) => {
  const methods = useForm({ mode: 'onBlur' });
  const { control, reset } = methods;
  const formErrors = methods.formState.errors;
  const { create } = useCreateAccount();

  const isConfirmationNeeded = hasFormValuesChanged(methods.watch(), []);

  const onSubmit = async (data: TUsers.CreateAccount) => {
    try {
      await create.mutateAsync({ ...data, method_id: methodID });
      setIsOpen(false);
      addMessageToast('Account successfully created', 'success');
      methods.reset();
    } catch (error) {
      addMessageToast(error);
    }
  };

  return (
    <ModalTemplate
      isConfirmationNeeded={isConfirmationNeeded}
      close={() => setIsOpen(false)}
      isOpen={isOpen}
      onBack={() => setIsOpen(false)}
      titleText={'Account adding'}
      hideBackdrop={false}
      reset={reset}>
      <FormWrapper onSubmit={onSubmit} methods={methods}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Grid container direction={'column'} rowGap={15} wrap={'nowrap'}>
            <Grid item>
              <Input
                name='account_login'
                label='Enter account login *'
                placeholder='Account email or phone number'
                rules={{ required: 'Please, enter your login' }}
                methods={methods}
              />
            </Grid>
            <Grid item>
              <Input
                name='account_name'
                label='Enter account name *'
                placeholder='Account Name'
                rules={{ required: 'Please, enter your account name' }}
                methods={methods}
              />
            </Grid>
            <Grid item>
              <Input
                name='account_id'
                label='Enter account id'
                placeholder='Account id'
                rules={{ required: 'Please, enter your account id' }}
                methods={methods}
              />
            </Grid>
          </Grid>
        </Box>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{
            borderRadius: 50,
            textTransform: 'none',
            height: 40,
            marginTop: 15,
            boxShadow: 'none'
          }}
          startIcon={<Add />}>
          Add
        </Button>
      </FormWrapper>
    </ModalTemplate>
  );
};
