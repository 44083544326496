import { FC } from 'react';

import { Box } from '@mui/material';

import { ModalTemplate } from 'shared/ui/modal-template';

interface IProps {
  message: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const MessageModal: FC<IProps> = ({ message, setIsOpen, isOpen }) => {
  return (
    <ModalTemplate
      isConfirmationNeeded={false}
      close={() => setIsOpen(false)}
      isOpen={isOpen}
      onBack={() => setIsOpen(false)}
      titleText={'Message'}
      hideBackdrop={false}
      reset={() => {}}>
      <Box sx={{ paddingBottom: '30px', textAlign: 'center' }}>{message || 'empty'}</Box>
    </ModalTemplate>
  );
};
