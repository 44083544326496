import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useGetMethods } from 'widgets/methods/api/useGetMethods';

import { FilterModal } from 'features/filter';
import { MatchTransaction } from 'features/match-transaction';
import { useChangeApplication } from 'features/match-transaction/api/useMatchTransaction';
import { DefaultTables } from 'features/method-tables/default';
import { MessageModal } from 'features/transaction-message';

import useFilterStore from 'shared/hooks/useFilterStore';
import useRefetchUsers from 'shared/hooks/useRefetchUsers';
import { DateRange, TFilters, TStatusType } from 'shared/services/types/common.types';
import { Layout } from 'shared/ui/layout';
import { addMessageToast } from 'shared/utils/addMessageToast';

import { useGetDate } from '../../shared/hooks/useGetDate';

export const Method = () => {
  const methods = useForm({ mode: 'onBlur' });
  const { reset } = methods;
  const { id: useParamsMethodID } = useParams();
  const methodID = parseInt(useParamsMethodID!);
  const { methods: methodsData } = useGetMethods();
  const { getDate } = useGetDate();
  const { match } = useChangeApplication();
  useRefetchUsers();
  const { setFilter, isFilterOpen, setIsFilterOpen } = useFilterStore();

  const [bankName, setBankName] = useState('');
  const [error] = useState<any>(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const [matchApp, setMatchApp] = useState<number>(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);
  const [accountNumber, setAccountNumber] = useState('');
  const [status, setStatus] = useState<TStatusType | undefined>();
  const [dateFilter, setDateFilter] = useState<DateRange>([null, null]);
  const [message, setMessage] = useState('');
  const [messageModal, setMessageModal] = useState(false);
  const [filterData, setFilterData] = useState<Record<string, any> | null>(null);

  const onSubmitFilters = (data: TFilters) => {
    setAccountNumber(data.accountNumber);
    setStatus(data.status);
    setDateFilter(data.dateRange);
    setFilterData(data);
    setPage(0);
    setIsFilterOpen(false);
  };

  const onClose = (isReset?: boolean) => {
    if (isReset) {
      setFilterData(filterData);
    }
    reset();
    setIsFilterOpen(false);
  };

  const openMessagesModal = () => {
    setMessageModal(true);
    setMessage(message);
  };

  const matchTransaction = (id: number) => {
    setIsOpen(true);
    setMatchApp(id);
  };

  const onMatchTransaction = async (data: any) => {
    try {
      await match.mutateAsync({
        transaction_id: data.transaction_id,
        application_id: matchApp
      });
      addMessageToast('Matched successfully', 'success');
      setIsOpen(false);
    } catch (error) {
      addMessageToast(error);
    }
  };

  useEffect(() => {
    setIsOpen(false);
    const methodLabel = methodsData?.find(method => method.id === methodID)?.name || '';
    setBankName(methodLabel);
  }, [useParamsMethodID, methodsData]);

  useEffect(() => {
    setAccountNumber('');
    setDateFilter([null, null]);
    setStatus(undefined);
  }, [methodID]);

  useEffect(() => {
    setFilter({
      date_from: getDate(0, dateFilter),
      date_to: getDate(1, dateFilter),
      account_id: accountNumber,
      status: status ? status : undefined
    });
  }, [methodID, dateFilter, accountNumber, status]);

  return (
    <>
      <Layout bankName={bankName} isSingleMethod={true}>
        <FilterModal
          methodID={methodID}
          onSubmit={onSubmitFilters}
          onClose={onClose}
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
          methods={methods}
          reset={reset}
          userData={filterData}
        />
        <DefaultTables
          matchTransaction={matchTransaction}
          methodID={methodID}
          pageSize={pageSize}
          setPageSize={setPageSize}
          page={page}
          setPage={setPage}
          status={status}
          accountNumber={accountNumber}
          dateFilter={dateFilter}
          onSubmitFilters={onSubmitFilters}
          openMessagesModal={openMessagesModal}
        />
        <MatchTransaction
          onSubmit={onMatchTransaction}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          error={error}
        />
        <MessageModal message={message} isOpen={messageModal} setIsOpen={setMessageModal} />
      </Layout>
    </>
  );
};
