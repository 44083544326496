import { FC } from 'react';

import { Add } from '@mui/icons-material';
import { Box, Button, Divider, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';

import { FormWrapper } from 'shared/ui/form-wrapper';
import { Input } from 'shared/ui/input';
import { ModalTemplate } from 'shared/ui/modal-template';
import { hasFormValuesChanged } from 'shared/utils';

interface IProps {
  isOpen: boolean;
  onSubmit: (data: any) => Promise<void>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  error: any;
}

export const MatchTransaction: FC<IProps> = ({ setIsOpen, isOpen, onSubmit }) => {
  const methods = useForm({ mode: 'onBlur' });
  const { reset, control } = methods;
  const formErrors = methods.formState.errors;
  const isConfirmationNeeded = hasFormValuesChanged(methods.watch(), []);

  return (
    <ModalTemplate
      isConfirmationNeeded={isConfirmationNeeded}
      close={() => {
        setIsOpen(false);
      }}
      isOpen={isOpen}
      titleText={'Match Transaction'}
      hideBackdrop={false}
      reset={reset}
      onBack={() => setIsOpen(false)}>
      <FormWrapper onSubmit={onSubmit} methods={methods}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Grid container direction={'column'} rowGap={15} wrap={'nowrap'}>
            <Grid item>
              <Input
                name='transaction_id'
                label='Enter transaction ID *'
                placeholder='Enter transaction ID'
                rules={{ required: 'Please, enter transaction ID' }}
                methods={methods}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ width: 1, m: '24px 0' }}></Divider>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{ m: 0, borderRadius: 50, textTransform: 'none', height: 40 }}
          startIcon={<Add />}
          onSubmit={onSubmit}>
          Match
        </Button>
      </FormWrapper>
    </ModalTemplate>
  );
};
