import { ChangeEvent, FC, useEffect } from 'react';

import { Grid, MenuItem, Pagination, Select, SelectChangeEvent, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

import styles from './pagination.module.sass';

interface IProps {
  total: number;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

export const TablePagination: FC<IProps> = ({ total, pageSize, setPageSize, page, setPage }) => {
  const currentMethod = useLocation();

  useEffect(() => {
    setPage(0);
  }, [currentMethod]);

  const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
    setPage(0);
    setPageSize(event.target.value as number);
  };

  const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
    setPage(page - 1);
  };

  return (
    <Grid
      className={styles.tablePagination}
      container
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{ padding: '18px 24px', borderRadius: '16px', background: '#fff' }}>
      <Grid item>
        <Grid container justifyContent='center' alignItems='center' sx={{ gap: '12px', height: 1 }}>
          <Typography variant='body1' sx={{ fontSize: 14, lineHeight: '20px', color: '#44464F' }}>
            Show result
          </Typography>
          <Select
            name='page-size'
            id='page-size'
            onChange={handlePageSizeChange}
            value={pageSize}
            sx={{
              borderRadius: '28px',
              minWidth: '91px',
              p: '0',
              '.MuiSelect-nativeInput': { fontSize: 14, lineHeight: '20px' },
              color: '#44464F'
            }}>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={40}>40</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid item>
        <Pagination
          color='primary'
          count={Math.max(1, Math.ceil(total / pageSize))}
          onChange={handlePageChange}
          page={page + 1}
          size='large'
          sx={{
            '.MuiPaginationItem-root': {
              maxWidth: 'none',
              fontSize: 14,
              lineHeight: '20px'
            }
          }}
        />
      </Grid>
    </Grid>
  );
};
