import { useEffect, useState } from 'react';

export const usePositionCalendar = (isOpen: boolean) => {
  const [calendarRect, setCalendarRect] = useState<DOMRect>();

  const handleCalendarOpen = () => {
    const calendar = document.getElementsByClassName('react-calendar')[0];
    if (calendar) {
      const rect = calendar.getBoundingClientRect();
      setCalendarRect(prev => (JSON.stringify(prev) !== JSON.stringify(rect) ? rect : prev));
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      handleCalendarOpen();
    });
    const observerM = new MutationObserver(() => {
      handleCalendarOpen();
    });
    observerM.observe(document.body, {
      attributes: true,
      characterData: true,
      childList: true,
      subtree: true,
      attributeOldValue: true,
      characterDataOldValue: true
    });
    resizeObserver.observe(document.body);
    return () => {
      observerM.disconnect();
      resizeObserver.disconnect();
    };
  }, [isOpen]);

  return { calendarRect };
};
