import { toFormData } from 'axios';

import { axiosInstance } from 'shared/api';
import { getUserUrl } from 'shared/api/config/api.config';

import { TPagination } from './types/common.types';
import { TUsers } from './types/users.types';

export const UsersService = {
  async getAccounts(data: TUsers.GetAccount) {
    const response = await axiosInstance.get<TUsers.GetAccountSuccess>(
      getUserUrl('/get-accounts'),
      {
        params: { ...data }
      }
    );

    return response;
  },
  async createAccount(data: TUsers.CreateAccount) {
    const response = await axiosInstance.post<TUsers.CreateAccount>(
      getUserUrl('/create-account'),
      toFormData(data)
    );

    return response;
  },

  async getUsers(data: TPagination) {
    const response = await axiosInstance.get<TUsers.GetUsersSuccess>(getUserUrl('/get-users'), {
      params: data
    });

    return response;
  },

  async createUser(data: TUsers.CreateUser) {
    const response = await axiosInstance.post<TUsers.UserData>(getUserUrl('/'), toFormData(data));

    return response;
  },

  async updateUser(data: TUsers.UpdateUser) {
    const response = await axiosInstance.patch<TUsers.UserData>(
      getUserUrl('/update'),
      toFormData(data)
    );

    return response;
  },

  async getMe() {
    const response = await axiosInstance.get<TUsers.UserDataMe>(getUserUrl('/get-current-user'));
    return response;
  }
};
