import { FC } from 'react';

import { format } from 'date-fns';
import { DatePicker } from 'react-date-picker';
//PRE_COOMMIT_ERROR
import { Noop } from 'react-hook-form';

import { TodayIcon } from 'shared/assets/icons';
import { DateRange } from 'shared/services/types/common.types';
import { ClearFormItem } from 'shared/ui/clear-form-button';

interface IProps {
  value: DateRange;
  onChange: React.Dispatch<React.SetStateAction<DateRange>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onBlur?: Noop;
}

export const DatePickerSlice: FC<IProps> = ({
  value,
  onChange,
  isOpen,
  setIsOpen,
  setIsDropdownOpen,
  onBlur
}) => {
  return (
    <DatePicker
      onChange={onChange}
      value={value}
      dayPlaceholder='dd'
      monthPlaceholder='mm'
      yearPlaceholder='yyyy'
      format='MM/dd/yyyy'
      calendarIcon={<TodayIcon />}
      showLeadingZeros={false}
      maxDate={new Date()}
      onBlur={onBlur}
      isOpen={isOpen}
      clearIcon={<ClearFormItem rightPercent={10} />}
      shouldCloseCalendar={({ reason }) => {
        return reason === 'buttonClick';
      }}
      onCalendarOpen={() => {
        setIsOpen(true);
        return;
      }}
      calendarProps={{
        locale: 'en-US',
        formatShortWeekday: (locale, date) => {
          return format(date, 'EEEEE');
        },
        next2Label: null,
        prev2Label: null,
        allowPartialRange: true,
        maxDate: new Date(),
        onViewChange({ view }) {
          setIsDropdownOpen(view === 'month');
        }
      }}
    />
  );
};
