import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { QRCodeCanvas } from 'qrcode.react';

import { TAuth } from 'shared/services/types/auth.types';

interface IProps {
  isQRLoading: boolean;
  getQR?: TAuth.TwoFactorCreate;
}

export const QrScan: FC<IProps> = ({ isQRLoading, getQR }) => {
  const searchParams = new URLSearchParams(getQR?.qr.split('?')[1]);
  const TWO_FACTOR_KEY = searchParams.get('secret');

  return (
    <>
      <Typography
        sx={{ width: '100%', textAlign: 'center', marginTop: '20px' }}
        variant='h5'
        maxWidth='365px'>
        Use Google Authentication app to scan this QR code
      </Typography>
      {!isQRLoading && getQR?.qr && (
        <QRCodeCanvas style={{ marginTop: '26px' }} value={getQR?.qr ?? ''} size={250} />
      )}
      <Typography
        sx={{ maxWidth: '100%', display: 'block', textAlign: 'center', marginTop: '24px' }}
        variant='body1'
        maxWidth='365px'
        fontWeight='500'
        letterSpacing='0.25px'>
        Or enter this code in application for authentication
      </Typography>
      <Box
        sx={{
          width: '100%',
          height: '27px',
          display: 'block',
          position: 'relative',
          marginTop: '12px'
        }}
        maxWidth='365px'>
        <Typography
          style={{
            position: 'absolute',
            top: '0%',
            left: '50%',
            transform: 'translate(-50%, 0)',
            fontWeight: 700,
            fontSize: '20px',
            textAlign: 'center'
          }}
          variant='h5'>
          {TWO_FACTOR_KEY}
        </Typography>
      </Box>
    </>
  );
};
