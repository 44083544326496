import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';

import { Add } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { AddAccountModal } from 'features/add-account';
import { AddMethodModal } from 'features/add-method';
import { useGetApplicationsExport } from 'features/applications/api/useGetApplicationExport';
import { useGetTransactionsExport } from 'features/transactions/api/useGetTransactionExport';
import { WithdrawalModal } from 'features/withdrawal';

import {
  DownloadCloudIcon,
  FilterAlt,
  HeaderArrow,
  WithdrawalHeaderIcon
} from 'shared/assets/icons';
import useFilterStore from 'shared/hooks/useFilterStore';
import useUser from 'shared/hooks/useUser';
import { TRoles } from 'shared/services/types/common.types';
import { MethodSelect } from 'shared/ui/method-select';
import { addMessageToast } from 'shared/utils/addMessageToast';
import { downloadExportFile } from 'shared/utils/downloadExportFile';

interface IProps extends PropsWithChildren {
  isSingleMethod?: boolean;
}

const ROLE_ACCESS = ['Администратор', 'Менеджер'] as TRoles[];

export const Header: FC<IProps> = ({ isSingleMethod }) => {
  const navigate = useNavigate();
  const { id: useParamsMethodID } = useParams();
  const methodID = parseInt(useParamsMethodID!);
  const { filter, setIsFilterOpen } = useFilterStore();

  const [isOpenWithdrawal, setIsOpenWithdrawal] = useState(false);
  const [isOpenAddMethod, setIsOpenAddMethod] = useState(false);
  const [modalAccountAdd, setModalAccountAdd] = useState(false);

  const { rolesAccess, user } = useUser();

  const isAccess = rolesAccess(['Администратор']);
  const downloadTransactionsTrigger = useRef(false);
  const downloadApplicationsDataTrigger = useRef(false);
  const filterParams = {
    methodID: methodID,
    ...filter
  };

  const {
    applications,
    refetchApplications,
    errorMessage: errorApplications,
    isFetching: isApplicationExportFetching
  } = useGetApplicationsExport(filterParams);
  const {
    transactions,
    refetchTransactions,
    errorMessage: errorTransactions,
    isTransactionsExportFetching
  } = useGetTransactionsExport(filterParams);

  const isLoadingDownload = isApplicationExportFetching || isTransactionsExportFetching;
  useEffect(() => {
    if (errorApplications) {
      addMessageToast(errorApplications?.detail);
    }
  }, [errorApplications]);

  useEffect(() => {
    if (errorTransactions) {
      addMessageToast(errorTransactions?.detail);
    }
  }, [errorTransactions]);

  const handleDownloadData = () => {
    refetchApplications();
    refetchTransactions();
    downloadApplicationsDataTrigger.current = true;
    downloadTransactionsTrigger.current = true;
  };

  useEffect(() => {
    if (applications && downloadApplicationsDataTrigger.current) {
      downloadExportFile(applications, 'applications');

      downloadApplicationsDataTrigger.current = false;
    }
  }, [applications]);
  useEffect(() => {
    if (transactions && downloadTransactionsTrigger.current) {
      downloadExportFile(transactions, 'transactions');
      downloadTransactionsTrigger.current = false;
    }
  }, [transactions]);

  const filterActive = methodID && Object.values(filter).some(item => item);

  return (
    <Box
      gridColumn='2'
      gridRow='1'
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 10,
        py: 7,
        px: 12,
        borderRadius: 4,
        bgcolor: '#fff',
        '.MuiButton-root': {
          padding: ' 10px 28px 10px 24px',
          minWidth: 'fit-content',
          maxHeight: 44,
          fontSize: 16,
          fontWeight: 500,
          letterSpacing: '0.25px',
          '&.MuiButton-contained': {
            fontSize: '14px',
            fontWeight: '400'
          }
        }
      }}>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '14px'
        }}>
        {methodID ? (
          <>
            <Box sx={{ height: '44px', cursor: 'pointer' }}>
              <HeaderArrow
                style={{
                  cursor: 'pointer',
                  marginTop: '11.5px',
                  color: '#185AC2'
                }}
                onClick={() => {
                  navigate('/');
                }}
              />
            </Box>
            <Typography
              variant='h3'
              color='primary'
              sx={{ fontSize: 28, lineHeight: '36px', fontWeight: '500' }}>
              Method
            </Typography>
            <Box>
              <MethodSelect useParamsMethodID={useParamsMethodID} />
            </Box>
          </>
        ) : (
          <Typography
            variant='h3'
            color='primary'
            sx={{ fontSize: 28, lineHeight: '36px', fontWeight: '500' }}>
            Methods
          </Typography>
        )}
      </Box>
      {!methodID && isAccess && (
        <Button
          variant='contained'
          startIcon={<Add />}
          onClick={() => {
            setIsOpenAddMethod(true);
          }}>
          Add method
        </Button>
      )}
      {!!methodID && user && ROLE_ACCESS.includes(user?.role) && (
        <Button
          variant='contained'
          onClick={() => {
            setModalAccountAdd(true);
          }}
          startIcon={<Add />}>
          Add account
        </Button>
      )}

      {isSingleMethod && (
        <>
          {user && ROLE_ACCESS.includes(user?.role) && (
            <Button
              variant='outlined'
              disabled={isLoadingDownload}
              sx={{
                position: 'relative'
              }}
              startIcon={<DownloadCloudIcon />}
              onClick={handleDownloadData}>
              {filterActive && (
                <Box
                  sx={{
                    width: 6,
                    height: 6,
                    borderRadius: '50%',
                    backgroundColor: 'red',
                    position: 'absolute',
                    top: 12,
                    right: 19
                  }}
                />
              )}
              Download data
            </Button>
          )}
          <Button
            variant='outlined'
            sx={{
              '.MuiButton-startIcon': {
                padding: '4px'
              }
            }}
            startIcon={<WithdrawalHeaderIcon />}
            onClick={() => {
              setIsOpenWithdrawal(true);
            }}>
            Withdrawal
          </Button>
        </>
      )}
      {!!methodID && (
        <Button
          variant='outlined'
          onClick={() => {
            setIsFilterOpen(true);
          }}
          startIcon={<FilterAlt />}>
          Filter
        </Button>
      )}

      <AddMethodModal isOpen={isOpenAddMethod} setIsOpen={setIsOpenAddMethod}></AddMethodModal>
      <WithdrawalModal isOpen={isOpenWithdrawal} setIsOpen={setIsOpenWithdrawal}></WithdrawalModal>
      <AddAccountModal
        isOpen={modalAccountAdd}
        setIsOpen={setModalAccountAdd}
        methodID={methodID}
      />
    </Box>
  );
};
